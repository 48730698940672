<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 resources-screen gray-bg assign-resources"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="resource-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_one-click-plan-add-period-help-online'
        }"
      >
        <template v-slot:left>
          <router-link
            :to="{
              name: 'r_one-click-plan-add-resources'
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("one-click", "plan-add", "set-plan") }}
        </div>
        <template v-slot:right>
          <button @click="savePlan">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <div v-if="selectedResource">
      <ul class="clebex-item-section">
        <li class="clebex-item-section-item">
          <div class="clebex-pill-link">
            <div class="clebex-item-content-wrapper">
              <span class="label larger-label">
                <div v-if="selectedResource.type !== 'type'">
                  {{
                    selectedResource.translations.find(
                      el => el.locale === locale
                    )
                      ? selectedResource.translations.find(
                          el => el.locale === locale
                        ).attributes.name
                      : selectedResource.name
                  }}
                </div>
                <div v-else>
                  {{
                    selectedResource.resource_type.translations.find(
                      el => el.locale === locale
                    )
                      ? selectedResource.resource_type.translations.find(
                          el => el.locale === locale
                        ).attributes.name
                      : selectedResource.resource_type.name
                  }}
                </div>
              </span>
            </div>
          </div>
        </li>
        <li class="clebex-item-section-item no-bottom-border light">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label larger-label">
                <div v-if="selectedResource.type !== 'level'">
                  {{ selectedResource.full_path.join(", ") }}
                </div>
                <div v-else>
                  {{ selectedResource.full_path.slice(1).join(", ") }}
                </div>
              </label>
            </div>
          </div>
        </li>
      </ul>

      <ul class="clebex-item-section overflow-hidden">
        <li
          class="clebex-item-section-item"
          :class="collapse.periods ? 'collapsed' : 'expanded'"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label larger-label">
                {{ displayLabelName("one-click", "plan-add", "periods") }}
              </label>
            </div>
            <span
              class="follow-up-icons cursor-pointer"
              @click="
                collapse.periods
                  ? (collapse.periods = false)
                  : (collapse.periods = true)
              "
            >
              <span class="follow-up-icon-item collapsable"
                ><icon icon="#cx-app1-arrow-right-12x12" width="12" height="12"
              /></span>
            </span>
          </div>
        </li>
        <section :class="collapse.periods ? 'collapsed' : 'expanded ten'">
          <li
            v-for="period in periods"
            :key="period.id"
            class="clebex-item-section-item no-bottom-border light"
          >
            <div
              class="clebex-item-content-wrapper cursor-pointer"
              @click="periodId = period.id"
            >
              <div class="clebex-section-input">
                <label class="clebex-section-input-label cursor-pointer">{{
                  period.name
                }}</label>
              </div>
              <span v-if="periodId === period.id" class="follow-up-icons">
                <span class="follow-up-icon-item">
                  <div class="svg-icon">
                    <svg
                      width="12"
                      height="12"
                      style="width: 12px; height: 12px;"
                    >
                      <use xlink:href="#cx-app1-checkmark"></use>
                    </svg>
                  </div>
                </span>
              </span>
            </div>
          </li>
        </section>
      </ul>
      <div class="clebex-item-section">
        <select-slots :slots="slots"></select-slots>
      </div>
    </div>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import SelectSlots from "@/components/plans/SelectSlots";
import { mapActions, mapState } from "vuex";
import dayjs from "dayjs";
import { DateTime } from "luxon";
import { getLang } from "@/services/http-service";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "OneClickPlanAddResourcesMode",
  mixins: [helpOnlineMixin],
  created() {
    if (!this.selectedResource) {
      this.$router.push({
        name: "r_one-click-plan-add-resources"
      });
    }
    this.getPeriods();
    this.getSlots({
      payload: {
        types: ["DAY", "HALF_DAY", "HOUR", "HALF_HOUR", "QUARTER_HOUR"],
        type: "default",
        date: dayjs()
          .add(1, "day")
          .format("YYYY-MM-DD"),
        timezone: DateTime.now().zoneName
      },
      noLoader: false
    });
  },
  data() {
    return {
      periodId: 1,
      collapse: {},
      helpSlug: "one-click-settings-plan-add-period"
    };
  },
  computed: {
    ...mapState("oneClick", ["periods", "plans"]),
    ...mapState("plan", ["slots", "selectedSlot"]),
    ...mapState("oneClick", ["selectedResource"]),
    locale() {
      return getLang();
    }
  },
  methods: {
    ...mapActions("oneClick", ["getPeriods", "createPlan"]),
    ...mapActions("plan", ["getSlots", "setSelectedSlot"]),
    savePlan() {
      if (
        this.selectedResource &&
        this.selectedSlot &&
        this.selectedSlot.slots &&
        this.selectedSlot.slots.length
      ) {
        const postObject = {
          level_id: this.selectedResource.level_id,
          period_id: this.periodId,
          resource_id: null,
          datetime_from: dayjs(this.selectedSlot.slots[0].datetime_from).format(
            "YYYY-MM-DD HH:mm"
          ),
          datetime_to: dayjs(this.selectedSlot.slots.at(-1).datetime_to).format(
            "YYYY-MM-DD HH:mm"
          ),
          ordering: this.plans && this.plans.length ? this.plans.length + 1 : 1,
          type: this.selectedSlot.type
        };

        switch (this.selectedResource.type) {
          case "resource":
            postObject.resource_id = this.selectedResource.id;
            break;
          case "type":
            postObject.resource_type_id = this.selectedResource.resource_type_id;
            break;
          case "level":
            postObject.level_id = this.selectedResource.all_parents[1].id;
            postObject.resource_level_id = this.selectedResource.level_id;
            break;
          default:
            break;
        }

        this.createPlan(postObject).then(() => {
          this.setSelectedSlot(null);
          this.$router.push({
            name: "r_one-click-plan"
          });
        });
      }
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    SelectSlots
  }
};
</script>
